<template>
  <div style="overflow: hidden !important;">
    <div class="landing-mundial">
      <div class="tools-mundial-cont">
        <div class="tools-mundial-cont__row-one">
          <div class="tools-mundial-cont__row-one__group">
            <div class="tools-mundial-cont__row-one__group__datags-cont">
              <GolstatsData
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
                :season="objectSeasonSelected"
              />
            </div>
            <div class="tools-mundial-cont__row-one__group__tops-cont">
              <TopMini
                :custom="colorsConfig"
                :await-for-season="awaitForSeasonOrSeasons"
                :season="objectSeasonSelected.id"
                :tournament="tournamentSelected.id"
                :is-cup="booleanIsTournamentsSelectedCup"
              />
            </div>
          </div>
          <div class="tools-mundial-cont__row-one__calendar" :class="tournamentSelected.isCup ? 'heightAux' : ''">
            <CalendarCup
              :key="updatescroll"
              v-if="booleanIsTournamentsSelectedCup"
              await-for-seasons
              :seasons="objectTournamentSelected.seasons"
              :is-short-version="true"
              route-name="matchAnalysis"
            />
            <Calendar
              v-else
              :key="updatescroll"
              type="onlycomponent"
              await-for-seasons
              is-landing
              is-green-header-color
              :seasons="objectTournamentSelected.seasons"
              route-name="matchAnalysis"
            />
          </div>
        </div>
        <div class="tools-mundial-cont__row-two">
          <div class="tools-mundial-cont__row-two__table-general">
            <div class="table-normal-landing" v-if="!booleanIsTournamentsSelectedCup">
              <div class="table-normal-landing__container-table">
                <Table
                  :season="objectSeasonSelected.id"
                  :custom="colorsConfig"
                  :await-for-season="awaitForSeasonOrSeasons"
                  :show-logos="objectTournamentSelected.showLogos"
                />
              </div>
            </div>

            <TableMundial
              v-else
              :custom="colorsConfig"
              :await-for-season="awaitForSeasonOrSeasons"
              :seasonget="objectSeasonSelected.id"
              :show-logos="objectTournamentSelected.showLogos"
            />
          </div>
          <div id="golTipsterLanding" class="tools-mundial-cont__row-two__bets-tool">
            <GolTipsterV2 id="Herramienta-apostadores" :are-live-games="showLive" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'ViewAll',
  components: {
    CalendarCup: () => import('@/components/Sections/Calendar/CalendarCup'),
    Calendar: () => import('@/components/Sections/Calendar/CalendarComponent'),
    TopMini: () => import('@/views/TopMini'),
    Table: () => import('@/components/Sections/Table/TableComponent'),
    GolstatsData: () => import('@/views/GolstatsDataGS2'),
    TableMundial: () => import('@/components/Sections/Table/TableComponentMundial'),
    GolTipsterV2: () => import('@/components/Sections/GolTipster-v2/Main'),
  },
  data() {
    return {
      url: 'https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live',
      // B2C
      url2: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      // GS20
      // url2: 'https://kefloixzy1.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      urlData: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      retries: 3,
      updatescroll: 0,
      showLive: false,
      showModal: false,
      category: { id: 1, name: 'Goles', bluer: false },
      colorsConfig: {
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        color: '#cbee6b',
        background: '#242424',
        colorheader: '#132839',
        backgroundheader: '#CCE8B5',
        headerBackground: '#132839',
        headerColor: '#CBEE6E',
        headerBorderColor: '#CBEE6E',
        header2Background: '#132839',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cbee6b',
        subHeaderColor: '#353535',
        headerBetToolBackground: '#242424',
        subheaderBetToolBackground: '#151515',
        bet_house: '',
        main_logo: '',
        secondary_logo: '',
        show_logo: false,
        url_base: '',
        type: 1,
        team_id: 0,
        categories: 'all',
      },
      listTournaments: [],
      listSeasonsByTournament: [],
      listSeasons: {},
      groupedSeasons: null,
      tournamentSelected: {
        seasonsForCalendar: [],
      },
      seasonSelected: {},
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn']),
    ...mapGetters('leagueGeneral', ['booleanIsTournamentsSelectedCup']),
    ...mapState('leagueGeneral', ['objectSeasonSelected', 'objectTournamentSelected']),
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
    awaitForSeasonOrSeasons() {
      return true;
    },
  },
  watch: {
    objectTournamentSelected() {
      this.updatescroll += 1;
    },
  },
  async created() {
    try {
      await this.getLivesData();
      await this.fillListTournaments();
    } catch (e) {
      throw new Error(e);
    }
  },
  methods: {
    async setTournamentSelected(val) {
      this.tournamentSelected = val;
      this.listSeasonsByTournament = [];
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelected.id]);
      this.seasonSelected = this.listSeasons[this.tournamentSelected.id];
    },
    async fillListTournaments() {
      try {
        const { data: tournaments } = await axios
          .get(this.url2)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillListTournaments();
            }
            return e;
          });
        this.setTournamentsData(tournaments);
      } catch (e) {
        throw new Error(e);
      }
    },
    setTournamentsData(tournaments) {
      this.createGroupedSeasons(tournaments);
      if (tournaments) {
        let cupTournament = null;
        let defaultTournament = null;
        for (const tournament of tournaments) {
          const tournamentAux = {
            name: tournament.tournament.name,
            id: tournament._id,
            logo: tournament.tournament.logo,
            showLogos: !!tournament.tournament.show_logos,
            isCup: !!tournament.tournament.tournament_type,
            seasons: tournament.seasons.reduce((seasons, season, index) => {
              if (index === 0) {
                seasons.push({ id: season.id, type: season.type, name: season.name });
                if (season.related_seasons && season.related_seasons.length) {
                  seasons.push(
                    ...season.related_seasons.map(relatedSeason => ({
                      id: relatedSeason.id,
                      type: relatedSeason.type,
                    })),
                  );
                }
              }
              return seasons;
            }, []),
          };
          this.listTournaments.push(tournamentAux);
          for (let indexSeason = 0; indexSeason < tournament.seasons.length; indexSeason++) {
            if (indexSeason == 0) {
              this.listSeasons[tournament._id] = {
                name: tournament.seasons[indexSeason].name,
                id: tournament.seasons[indexSeason].id,
                logo: tournament.seasons[indexSeason].logo,
              };
            }
          }
          // if (tournament._id === 33) {
          //   cupTournament = tournamentAux;
          // }
          if (tournament._id === 1) {
            defaultTournament = tournamentAux;
          }
        }
        this.setTournamentSelected(cupTournament || defaultTournament);
      }
      this.listSeasonsByTournament.push(this.listSeasons[this.tournamentSelected.id]);
      this.seasonSelected = this.listSeasons[this.tournamentSelected.id];
    },
    createGroupedSeasons(tournamentsAndSeasons) {
      this.groupedSeasons = tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
    async getLivesData() {
      try {
        const { data: games } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLivesData();
            }
            return e;
          });
        if (games) {
          this.showLive = true;
        } else {
          this.showLive = false;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    openLive() {
      if (this.showLive) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
  },
  mounted() {},

  beforeMount() {},
  beforeDestroy() {},
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
.select.is-info select:hover,
.select.is-info select.is-hovered {
  border-color: #bfc9d2 !important;
  font-family: 'Roboto-Medium' !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.select.is-info select {
  border-color: #bfc9d2 !important;
  font-family: 'Roboto-Medium' !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}
</style>
<style lang="scss" scoped>
.heightAux {
  @media screen and (max-width: 500px) {
    height: 500px !important;
  }
}
.table-normal-landing {
  display: flex;
  justify-content: center;
  &__container-table {
    width: 100%;
    max-width: 618px;
    height: auto;
  }
}
.color-active {
  color: black !important;
  background-color: #cbee6d !important;
  img {
    filter: brightness(0%) !important;
  }
}
.color-active:hover {
  cursor: pointer;
  opacity: 0.8;
}
.tools-mundial-cont {
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 20px;
  &__row-one {
    width: 100%;
    height: 480px;
    display: inline-flex;
    @media screen and (max-width: 1328px) {
      display: inline-block;
      margin-bottom: 22px;
      height: auto;
    }
    &__group {
      width: 638px;
      display: inline-flex;
      height: auto;
      @media screen and (max-width: 1328px) {
        width: 100%;
        height: 480px;
      }
      @media screen and (max-width: 628px) {
        width: 100%;
        height: auto;
      }
      @media screen and (max-width: 428px) {
        display: inline-block;
      }
      &__datags-cont {
        width: 302.5px;
        height: auto;
        display: inline-block;
        border: 1px solid #c0c0c0;
        @media screen and (max-width: 1328px) {
          width: 49%;
          height: 480px;
        }
        @media screen and (max-width: 628px) {
          width: 49.8%;
          height: 412px;
        }
        @media screen and (max-width: 428px) {
          width: 100%;
          height: 151px;
        }
      }
      &__tops-cont {
        width: 303.5px;
        height: auto;
        // background: #e8e8e8;
        margin-left: 32px;
        display: inline-block;
        @media screen and (max-width: 1328px) {
          width: 49%;
          margin-left: 25px;
          height: 480px;
        }
        @media screen and (max-width: 628px) {
          margin-left: 2px;
          width: 49.8%;
          height: 410px;
        }
        @media screen and (max-width: 428px) {
          width: 100%;
          margin-left: 0%;
          margin-top: 25px;
        }
      }
    }
    &__calendar {
      width: 600px;
      margin-left: 32px;
      @media screen and (max-width: 1328px) {
        width: 100%;
        height: auto; //pasara a ser auto
        margin-left: 0;
        margin-top: 25px;
      }
      @media screen and (max-width: 828px) {
        width: 100%;
        margin-left: 0;
        margin-top: 25px;
      }
      @media screen and (max-width: 628px) {
        width: 100%;
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
  &__row-two {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 72px;
    @media screen and (max-width: 768px) {
      margin-top: 54px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 12px;
    }
    &__table-general {
      width: 100%;
      display: inline-block;
      height: auto; //pasara a ser auto
    }
    &__bets-tool {
      width: 100%;
      display: inline-block;
      height: auto; //pasara a ser auto
      margin-top: 72px;
      @media screen and (max-width: 768px) {
        margin-top: 54px;
      }
      @media screen and (max-width: 500px) {
        margin-top: 12px;
      }
    }
  }
}
.landing-mundial {
  width: 100%;
  max-width: 1248px;
  display: inline-block;
  margin: 0 auto;
  height: auto;
  justify-content: center;
  margin-bottom: 0 !important;
}
.header-landing-mundial-movil {
  display: none;
  width: 100%;
  height: 168px;
  justify-content: center;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    display: inline-block;
  }
  &__data {
    width: 100%;
    height: 72px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
    &__tournament-cont {
      width: 65%;
      height: 41px;
      display: inline-block;
      justify-content: left;
      align-content: center;
      align-items: center;

      &__label {
        font-family: 'Roboto-Regular';
        font-size: 15px;
        height: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.13px;
        text-align: left;
        color: #3c444b;
        margin-right: 10px;
        width: 20%;
        margin-left: 10px;
      }
      &__select {
        width: 95%;
        height: 41px;
        margin-left: 10px;
        @media screen and (max-width: 400px) {
          width: 89%;
        }
      }
    }

    &__live-bottom-cont {
      width: 35%;
      height: 41px;
      display: inline-flex;
      justify-content: right;
      align-content: center;
      align-items: center;
      &__buttom {
        width: 115px;
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        border-radius: 8px;
        background-color: #e8e8e8;
        font-family: 'Avenir-Pro-Bold';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #c9ccce;
        margin-right: 10px;
        padding-left: 10px;
        margin-top: 47px;

        img {
          margin-left: 3px;
          filter: grayscale(1);
        }
      }
    }
  }
  &__logo-sponsor-cont {
    width: 100%;
    height: 85.7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-left: 0%;
    align-items: center;
    position: relative;
    &__sep {
      width: 100%;
      height: 2px;
      border-bottom: 1px dotted #aeb0b2;
      position: absolute;
    }
    &__imgcont {
      width: 212px;
      height: 85.7px;
      background: white;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      img {
        width: 68%;
      }
    }
  }
}
.header-landing-mundial {
  width: 100%;
  display: inline-flex;
  height: 110px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
    display: none;
  }
  &__tournament-cont {
    width: 27.36%;
    height: 41px;
    display: inline-flex;
    justify-content: left;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 1120px) {
      width: 30%;
    }
    @media screen and (max-width: 960px) {
      width: 31%;
    }
    @media screen and (max-width: 860px) {
      width: 44.5%;
    }
    @media screen and (max-width: 600px) {
      width: 45%;
    }

    &__label {
      font-family: 'Roboto-Regular';
      font-size: 18px;
      height: 41px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 35px;
      letter-spacing: -0.13px;
      text-align: left;
      color: #3c444b;
      margin-right: 10px;
      width: 20%;
      margin-left: 10px;
      @media screen and (max-width: 560px) {
        margin-left: 3px;
        margin-right: 5px;
        width: 25%;
      }
    }
    &__select {
      width: 80%;
      height: 41px;
    }
  }
  &__sep-cont {
    width: 6.24%;
    height: 41px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    &__line {
      width: 2px;
      height: 16px;
      border-right: 1px solid #aeb0b2;
    }
    @media screen and (max-width: 860px) {
      width: 7.24%;
    }
    @media screen and (max-width: 650px) {
      width: 5.24%;
    }
  }
  &__sep-cont-movil {
    width: 6.24%;
    height: 41px;
    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    &__line {
      width: 2px;
      height: 16px;
      border-right: 1px solid #aeb0b2;
    }
    @media screen and (max-width: 860px) {
      display: inline-flex;
      width: 7.24%;
    }
    @media screen and (max-width: 650px) {
      width: 6.24%;
    }
  }
  &__live-bottom-cont {
    width: 9.21%;
    height: 41px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media screen and (max-width: 1120px) {
      width: 11.21%;
    }
    @media screen and (max-width: 960px) {
      width: 13.21%;
    }
    @media screen and (max-width: 860px) {
      width: 16.21%;
    }
    @media screen and (max-width: 600px) {
      width: 18.21%;
    }

    &__buttom {
      width: 115px;
      height: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 8px;
      background-color: #e8e8e8;
      font-family: 'Avenir-Pro-Bold';
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #c9ccce;

      padding-left: 10px;
      img {
        margin-left: 3px;
        filter: grayscale(1);
      }
    }
  }
  &__logo-sponsor-cont {
    width: 55.85%;
    height: 85.7px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    margin-left: 2.4%;
    align-items: center;
    position: relative;
    @media screen and (max-width: 1120px) {
      width: 50%;
    }
    @media screen and (max-width: 960px) {
      width: 47%;
    }
    @media screen and (max-width: 860px) {
      margin-left: 0%;
      width: 212px;
      justify-content: right;
    }
    @media screen and (max-width: 650px) {
      width: 180px;
    }
    @media screen and (max-width: 600px) {
      width: 150px;
    }
    &__sep {
      width: 100%;
      height: 2px;
      border-bottom: 1px dotted #aeb0b2;
      position: absolute;
      @media screen and (max-width: 860px) {
        display: none;
      }
    }
    &__imgcont {
      width: 212px;
      height: 85.7px;
      background: white;
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      @media screen and (max-width: 650px) {
        width: 180px;
      }
      @media screen and (max-width: 600px) {
        width: 150px;
      }
      img {
        width: 78%;
        @media screen and (max-width: 860px) {
          width: 53%;
        }
        @media screen and (max-width: 600px) {
          width: 65%;
        }
      }
    }
  }
}

.season-and-team {
  width: 100%;
  margin: 10px 0;
  display: none;

  @media screen and (max-width: 566px) {
    display: block;
  }
}
</style>
